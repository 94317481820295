import React, { FC, useCallback, useRef, useState } from 'react'
import styled from 'styled-components';

import iconPlay from '../../../../../assets/images/svg/play.svg';

import PresentMp4 from '../../../../../assets/videos/present.mp4'
import PresentOgv from 'file-loader!../../../../../assets/videos/present.ogv'
import PresentWebm from '../../../../../assets/videos/present.webm'

const VideoPlayer: FC = () => {

	const ref = useRef<HTMLVideoElement>()
	const [paused, setPaused] = useState(true)

	const toggleVideo = useCallback(() => {
		if (ref.current.paused) {
			setPaused(false)
			ref.current.play()
		} else {
			setPaused(true)
			ref.current.pause()
		}
	}, [ref])

  return (
		<VideoContainer>

			<video ref={ref} onClick={toggleVideo}>
				<source src={PresentMp4} type='video/mp4' />
				<source src={PresentWebm} type='video/webm' />
				<source src={PresentOgv} type='video/ogv' />
			</video>

			{paused && (
				<VideoAction>
					<VideoActionIcon />
				</VideoAction>
			)}

		</VideoContainer>
  );
};

const VideoContainer = styled.div`
	position: relative;
	cursor: pointer;
	border: 1px solid #F9F9FA;
	box-shadow: 7px 8px 32px rgb(241 241 241 / 64%);
`;

const VideoAction = styled.div`
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(1);
	transition: .15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	border-radius: 50%;
	background: rgba(255, 255, 255, 1);
	padding: 20px;
	opacity: 0.6;

	${VideoContainer}:hover & {
		transform: translate(-50%, -50%) scale(1.2);
	}
`;

const VideoActionIcon = styled.div`
	display: block;
	width: 46px;
	height: 46px;
	background: no-repeat center / contain url(${iconPlay})
`;

export default VideoPlayer;
