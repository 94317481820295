import { AccordionItemData } from '@/types';
import { v4 as uuidv4 } from 'uuid';

export const mainData = {
  tag: 'Вопросы и ответы',
  title: 'Отвечаем на самые популярные вопросы',
};

export const faqData: AccordionItemData[] = [
  {
    id: uuidv4(),
    question: 'Для чего нужен Личный кабинет?',
    answer:
      'Личный кабинет — это сервис, куда вы загружаете в формате pdf сведения о состоянии лицевого счета с сайта Госуслуг о вашем индивидуальном пенсионном счете, где отражается, какая сумма сформирована, кем она управляется и какая доходность начислена, когда и как вам можно будет получить выплату. Также можно подгрузить цифры по договорам негосударственного пенсионного обеспечения для помощи в получении социального налогового вычета и подаче заявления на выплаты за счет средств пенсионных накоплений.',
  },
  {
    id: uuidv4(),
    question: 'Как получить подробную консультацию по моей выплате?',
    answer:
      '1.	Регистрируетесь в личном кабинете на сайте Выплаты. 2.	Загружаете сведения о состоянии лицевого счета с сайта Госуслуги. 3.	Отправляете запрос в онлайн-поддержку сайта Выплаты для персональной консультации по вашей выписке. 4.	С вами связывается специалист и подробно разбирает сведения и цифры, дает рекомендации по выплате и помощи в подаче документов.',
  },
];
