import { SOCIAL_LINKS } from '@/constants';
import { Chart, Clipboard, Phone, Ruble } from '@/ui/iconComponents';

export const mainData = {
  tag: 'Шаги',
  title: '4 шага вместе с Вами',
  link: SOCIAL_LINKS.whatsapp,
  linkText: 'Получить консультацию',
};

export const textData = [
  {
    Icon: Phone,
    title: 'Консультируем',
    description:
      'Первая бесплатная консультация от квалифицированных и компетентных специалистов по вашему запросу',
  },
  {
    Icon: Chart,
    title: 'Рекомендуем',
    description:
      'Разбираем ваш персональный кейс, делаем расчеты, выявляем плюсы и минусы и даем рекомендации по вашей выплате',
  },
  {
    Icon: Clipboard,
    title: 'Действуем',
    description:
      'Помогаем подготовить заявление на выплату пошагово и отправляем документы в негосударственный пенсионный фонд',
  },
  {
    Icon: Ruble,
    title: 'Получаем',
    description:
      'Юридическое сопровождение нашей компании позволит вам в установленные сроки получить свою выплату',
  },
];
