import { Container } from '@/components';
import { COLORS, PADDINGS } from '@/constants';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { companiesWeWorkedData } from './data';

const CompaniesWeWorked: FC = () => {
  return (
    <Root>
      <StyledContainer>
        <Swiper
          modules={[FreeMode]}
          slidesPerView={'auto'}
          spaceBetween={24}
          freeMode={true}
        >
          {companiesWeWorkedData.map((item) => {
            return (
              <SwiperSlide key={item.id}>
                <SlideContent href={item.link || undefined}>
                  <img src={item.src} alt={item.nameCompany} />
                </SlideContent>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: ${PADDINGS.paddingBetweenSectionsLarge};
  .swiper {
    overflow: initial;
  }
  .swiper-slide {
    width: 180px;
  }

  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsSmall};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsMedium};
  }
`;

const SlideContent = styled.a`
  background-color: ${COLORS.grayLight};
  border-radius: 16px;
  width: 180px;
  min-height: 98px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 16px;
  filter: saturate(0);
  transition: filter 0.3s ease-in;
  :hover {
    filter: saturate(1);
  }
`;

export default CompaniesWeWorked;
