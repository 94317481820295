import { Container, SectionTitle, Tag } from '@/components';
import {
  COLORS,
  LINKS,
  PADDINGS,
  SECTION_IDS,
  SOCIAL_LINKS,
  TYPOGRAPHY,
} from '@/constants';
import { UiLink } from '@/ui/components';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mainData } from './data';

const ForWhom: FC = () => {
  return (
    <section id={SECTION_IDS.forWhom}>
      <StyledContainer>
        <StyledTag text={mainData.tag} />
        <StyledSectionTitle>{mainData.mainTitle}</StyledSectionTitle>
        <List>
          <Item>
            <ItemInfo>
              <ItemTitle>{mainData.soonToRetire.title}</ItemTitle>
              <ItemDescription>
                {mainData.soonToRetire.description}
              </ItemDescription>
              <UiLink
                link={mainData.soonToRetire.link}
                text={mainData.soonToRetire.linkText}
              />
            </ItemInfo>
            <ImageWrap>
              <StaticImage
                src="../../../assets/images/man.png"
                alt="Пенсионеры"
              />
              <AgeInfo>
                <AgeText>{mainData.soonToRetire.age}</AgeText>
                <span>Возраст</span>
              </AgeInfo>
            </ImageWrap>
          </Item>
          <Item>
            <ItemInfo>
              <ItemTitle>{mainData.thinkAboutRetirement.title}</ItemTitle>
              <UiLink
                size="small"
                link={mainData.thinkAboutRetirement.link}
                text={mainData.thinkAboutRetirement.linkText}
              />
            </ItemInfo>
            <ImageWrap>
              <StaticImage
                src="../../../assets/images/girl.png"
                alt="Пенсионеры"
              />
              <AgeInfo>
                <AgeTextMini>{mainData.thinkAboutRetirement.age}</AgeTextMini>
                <span>Возраст</span>
              </AgeInfo>
            </ImageWrap>
          </Item>
        </List>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
  padding-bottom: ${PADDINGS.paddingBetweenSectionsLarge};
  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsMedium};
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 12px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 64px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title3SemiBold32};
    margin-bottom: 32px;
  }
`;

const ImageWrap = styled.div`
  position: absolute;
  max-width: 249px;
  @media (max-width: 1100px) {
    position: relative;
  }
  @media (max-width: 650px) {
    align-self: center;
  }
`;

const AgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -12px;
  right: -32px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background-color: ${COLORS.additionalGreen};
  color: ${COLORS.white};
  span {
    ${TYPOGRAPHY.caption1Medium14};
    margin-top: -7px;
  }
  @media (max-width: 1439px) {
    bottom: 0;
    right: 0;
  }
`;

const AgeText = styled.p`
  ${TYPOGRAPHY.headline2SemiBold22};
`;

const AgeTextMini = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 32px;
  background-color: ${COLORS.primaryLight};
  grid-column: 1/8;
  border-radius: 28px;
  min-height: 368px;
  & ${ImageWrap} {
    right: 9.56%;
    top: 50%;
    transform: translateY(-50%);
  }

  :nth-child(2) {
    grid-column: 8 / -1;
    background-color: ${COLORS.grayLight};
    & ${ImageWrap} {
      right: 4.95%;
    }
    & ${AgeInfo} {
      right: 0;
      bottom: 0;
    }
  }

  @media (max-width: 1439px) {
    grid-column: 1/7;
    & ${ImageWrap} {
      right: 24px;
    }
    :nth-child(2) {
      grid-column: 7/-1;
    }
  }
  @media (max-width: 1200px) {
    padding: 22px;
  }
  @media (max-width: 1100px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :nth-child(n) {
      grid-column: auto;
      & ${ImageWrap} {
        right: auto;
        top: auto;
        transform: none;
      }
    }
  }
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemInfo = styled.div`
  position: relative;
  z-index: 1;
  @media (max-width: 650px) {
    margin-bottom: 32px;
  }
`;

const ItemTitle = styled.h3`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};
  margin-bottom: 16px;
  max-width: 240px;
  @media (max-width: 1100px) {
    max-width: 310px;
  }
  @media (max-width: 650px) {
    ${TYPOGRAPHY.headline1Medium24};
  }
`;

const ItemDescription = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  max-width: 240px;
  margin-bottom: 32px;
  @media (max-width: 1100px) {
    max-width: 350px;
  }
  @media (max-width: 700px) {
    max-width: 300px;
  }
  @media (max-width: 650px) {
    max-width: none;
  }
`;

export default ForWhom;
