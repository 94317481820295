import { useSwiperRef } from '@/hooks/useSwiperRef';
import { Arrow } from '@/ui/iconComponents';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Container, SectionTitle } from '@/components';
import { COLORS, PADDINGS, TYPOGRAPHY } from '@/constants';
import { UiLink } from '@/ui/components';
import { slidesData } from './data';

import 'swiper/css';
import 'swiper/css/pagination';

const Hero: FC = () => {
  const [prevEl, prevBtnRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextBtnRef] = useSwiperRef<HTMLButtonElement>();

  return (
    <section>
      <SliderWrapper>
        <ButtonsContainer>
          <PrevButton ref={prevBtnRef}>
            <Arrow width={32} height={32} />
          </PrevButton>
          <NextButton ref={nextBtnRef}>
            <Arrow width={32} height={32} />
          </NextButton>
        </ButtonsContainer>

        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
          }}
          pagination={{ clickable: true }}
          navigation={{
            prevEl,
            nextEl,
          }}
        >
          {slidesData.map((slide, index) => {
            return (
              <SwiperSlide key={index}>
                <div>
                  <SlideContainer>
                    <SlideInfo>
                      <SlideTitle>{slide.title}</SlideTitle>
                      <SlideDescription>{slide.description}</SlideDescription>

                      <UiLink
                        size="large"
                        link={slide.link}
                        text={slide.linkText}
                      />
                    </SlideInfo>
                    <ImageWrapper>
                      <img src={slide.imgPath} alt={slide.title} />
                    </ImageWrapper>
                  </SlideContainer>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderWrapper>
    </section>
  );
};

const SliderWrapper = styled.div`
  position: relative;
  background-color: ${COLORS.primaryLight};
`;

const ButtonsContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  padding-left: 6.11%;
  padding-right: 6.11%;
  @media (min-width: 1440px) {
    padding-left: 88px;
    padding-right: 88px;
  }
  @media (max-width: 1439px) {
    padding-left: ${PADDINGS.commonInPixelsMedium};
    padding-right: ${PADDINGS.commonInPixelsMedium};
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const SliderNavBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  z-index: 3;
  :disabled {
    opacity: 0;
    cursor: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const NextButton = styled(SliderNavBtn)``;

const PrevButton = styled(SliderNavBtn)`
  transform: rotate(180deg);
`;

const SlideContainer = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 15.5%;
  padding-right: 15.5%;
  @media (min-width: 1440px) {
    padding-left: 223px;
    padding-right: 223px;
  }
  @media (max-width: 1439px) {
    padding-left: ${PADDINGS.commonInPercent};
    padding-right: ${PADDINGS.commonInPercent};
  }
  @media (max-width: 1300px) {
    padding-left: ${PADDINGS.commonInPixelsLarge};
    padding-right: ${PADDINGS.commonInPixelsLarge};
  }
  @media (max-width: 1024px) {
    padding: 48px ${PADDINGS.commonInPixelsMedium};
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    padding-left: ${PADDINGS.commonInPixelsSmall};
    padding-right: ${PADDINGS.commonInPixelsSmall};
  }
`;

const SlideInfo = styled.div`
  max-width: 485px;
  margin-right: 30px;
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const SlideTitle = styled(SectionTitle)`
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    ${TYPOGRAPHY.title4Medium28};
  }
`;

const SlideDescription = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }
`;

const ImageWrapper = styled.div`
  max-width: 485px;
  @media (max-width: 1024px) {
    align-self: center;
  }
`;

export default Hero;
