import React from 'react';
import { MainLayout } from '@/layouts';
import { ContactUs, MainHeading, Reviews, Seo, Services } from '@/components';
import { SeoData } from '@/types';
import retireds from '@/assets/images/retireds.png';
import { About, Faq, ForWhom, Hero, Steps } from '@/pageComponents/Index';

export default function Home() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Социальный проект «Выплаты»',
    keywords:
      'Пенсионные накопления,выплата накопительной пенсии, льготная пенсия,персональная консультация, расчёт по выплате, оформление и подача документов на выплату, пенсионер, пенсия, единовременная выплата, негосударственный пенсионный фонд, пенсионный аудит, накопления, инвестирование, финансовый план,пенсионные программы, обязательное пенсионное страхование, негосударственное пенсионное обеспечение, нпф, опс, нпо, выплата, срочная выплата, пожизненная выплата, накопительная пенсия',
    sharedImage: {
      url: retireds,
    },
  };

  return (
    <MainLayout>
      <Seo data={seoData} />
      <MainHeading text={seoData.metaTitle} />
      <Hero />
      <About />
      <ForWhom />
      <Steps />
      <Services />
      <Reviews />
      <Faq />
      <ContactUs />
    </MainLayout>
  );
}
