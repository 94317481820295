import { LINKS, SOCIAL_LINKS } from '@/constants';

export const mainData = {
  tag: 'Для кого',
  mainTitle: 'Проект для вас, если',
  soonToRetire: {
    title: 'Скоро на пенсию',
    description:
      'Вы моложе 1967 года рождения и ваша трудовая пенсия не за горами, а может быть, вы врач, учитель или нефтяник и вам уже назначена льготная пенсия? Узнайте, как получить свои накопления!',
    link: LINKS.calculator,
    linkText: 'Рассчитать накопления',
    age: '50+',
  },
  thinkAboutRetirement: {
    title: 'Задумались о накоплениях',
    link: SOCIAL_LINKS.whatsapp,
    linkText: 'Оставить заявку',
    age: '30+',
  },
};
