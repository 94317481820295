export const aboutData = {
  tag: 'О проекте',
  title: 'Интернет-проект «Выплаты»',
  mainDescription:
    'Современный интернет-проект — это онлайн-помощь гражданам, проживающим в любой точке России, в расчете порога назначения накопительной пенсии, заполнении и подаче документов на выплату в ваш негосударственный пенсионный фонд! А еще мы поможем вам узнать:',
  descriptionList: [
    {
      text: '	КТО обслуживает ваш накопительный пенсионный счет, и КАКАЯ там сумма;',
    },
    {
      text: '	ПОЧЕМУ вам нужно предварительно делать расчеты для единовременной выплаты;',
    },
    {
      text: '	КАК увеличить ваши пенсионные накопления, и что такое договоры ОПС и НПО.',
    },
  ],
};

export const mainNumbersData = [
  {
    title: '+ 10 000 клиентов',
    description:
      'Мы получили огромный опыт в области пенсионных консультаций и финансовой грамотности во многих регионах России',
  },
  {
    title: 'с 2011 года',
    description:
      'Мы — успешная команда профессиональных консультантов на рынке финансовых услуг с онлайн-поддержкой',
  },
  {
    title: '+ 200 000 рублей',
    description:
      'Мы готовы помочь каждому из вас вовремя подать заявление и получить сумму накопительной пенсии из любого Фонда',
  },
];
