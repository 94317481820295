import { Container, SectionTitle, Tag } from '@/components';
import { COLORS, PADDINGS, SECTION_IDS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';
import { MainNumbers } from './components';
import { VideoPlayer } from './components/VideoPlayer';
import { aboutData } from './data';

const About: FC = () => {
  return (
    <Root id={SECTION_IDS.about}>
      <StyledContainer>
        <InfoWrapper>
          <VideoWrap>
            <VideoPlayer />
          </VideoWrap>
          <DescriptionWrap>
            <StyledTag text={aboutData.tag} />
            <Title>{aboutData.title}</Title>
            <TextDescription>{aboutData.mainDescription}</TextDescription>
            <ul>
              {aboutData.descriptionList.map((item, index) => (
                <DescriptionItem key={index}>{item.text}</DescriptionItem>
              ))}
            </ul>
          </DescriptionWrap>
        </InfoWrapper>
        <MainNumbers />
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  @media (max-width: 900px) {
    overflow: hidden;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: ${PADDINGS.paddingBetweenSectionsLarge};
  padding-bottom: 12px;
  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsSmall};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1439px) {
    padding-left: 0;
  }
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const VideoWrap = styled.div`
  margin-top: 50px;
  margin-right: 32px;
  @media (max-width: 1439px) {
    margin-top: 0;
  }
  @media (max-width: 1200px) {
    margin-right: 32px;
  }
  @media (max-width: 1024px) {
    align-self: center;
    margin-right: 0;
  }
`;

const DescriptionWrap = styled.div`
  max-width: 690px;
  @media (max-width: 1024px) {
    max-width: none;
    margin-bottom: 48px;
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 24px;
`;

const Title = styled(SectionTitle)`
  margin-bottom: 32px;
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3SemiBold32};
    margin-bottom: 20px;
  }
`;

const TextDescription = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  margin-bottom: 32px;
`;

const DescriptionItem = styled.li`
  text-indent: 12px;
  position: relative;
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black};
  ::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 2px;
    left: 0;
    top: 11px;

    background-color: ${COLORS.additionalGreen};
  }
`;

export default About;
