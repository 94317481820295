import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { mainNumbersData } from '../../data';

const MainNumbers: FC = () => {
  return (
    <SliderContainer>
      <Swiper
        modules={[Pagination]}
        slidesPerView={3}
        spaceBetween={23}
        pagination={{ clickable: true }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
        }}
      >
        {mainNumbersData.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <SlideContent>
                <SlideTitle>{item.title}</SlideTitle>
                <SlideDescription>{item.description}</SlideDescription>
              </SlideContent>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  .swiper {
    overflow: initial;
  }
  .swiper-wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .swiper-pagination {
    display: none;
  }
  .swiper-slide {
    display: flex;
    height: auto;
  }
  body & .swiper-pagination-bullet {
    background-color: ${COLORS.primaryLight};
  }
  body & .swiper-pagination-bullet-active {
    background-color: ${COLORS.blue1};
  }
  body & .swiper-horizontal > .swiper-pagination-bullets,
  body & .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }
  @media (max-width: 900px) {
    .swiper-pagination {
      display: block;
    }
  }
`;

const SlideContent = styled.div`
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.grayLighter};
  box-shadow: 7px 8px 32px rgba(241, 241, 241, 0.64);
  border-radius: 16px;
  padding: 24px 16px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  ::before,
  ::after {
    content: '';
    position: absolute;
    z-index: 0;
    background-color: rgba(54, 193, 134, 0.1);
    border-radius: 50%;
  }
  ::before {
    width: 32px;
    height: 32px;
  }
  ::after {
    width: 105px;
    height: 105px;
  }
  .swiper-slide:nth-child(1) & {
    ::before {
      right: 29.9%;
      top: -16px;
    }
    ::after {
      right: -13.5%;
      bottom: -30%;
    }
  }
  .swiper-slide:nth-child(2) & {
    ::before {
      content: none;
    }
    ::after {
      right: -13.5%;
      top: -30%;
    }
  }
  .swiper-slide:nth-child(3) & {
    ::before {
      right: -11px;
      top: 20%;
    }
    ::after {
      right: -13.5%;
      bottom: -30%;
    }
  }
`;

const SlideTitle = styled.p`
  ${TYPOGRAPHY.title2SemiBold36};
  color: ${COLORS.additionalGreen};
  letter-spacing: 0.01em;
  margin-bottom: 8px;
`;

const SlideDescription = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
`;

export default MainNumbers;
