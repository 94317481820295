import banner1 from '@/assets/images/banners/banner1.png';
import banner2 from '@/assets/images/banners/banner2.svg';
import banner3 from '@/assets/images/banners/banner3.svg';
import { LINKS, SOCIAL_LINKS } from '@/constants';

export const slidesData = [
  {
    title: 'Рассчитать ваши пенсионные накопления',
    description:
      'С помощью калькулятора вы сможете рассчитать пенсионные накопления, а мы расскажем как их можно получить',
    link: LINKS.calculator,
    linkText: 'Рассчитать накопления',
    imgPath: banner1,
  },
  {
    title: 'Как накопить миллион',
    description:
      'С помощью индивидуального пенсионного плана вы сможете самостоятельно накопить сумму на пенсию, обучение ребенка или крупную покупку',
    link: SOCIAL_LINKS.whatsapp,
    linkText: 'Узнать подробнее',
    imgPath: banner2,
  },
  {
    title: 'Записаться на консультацию',
    description:
      'С помощью технической поддержки вы сможете оставить заявку на индивидуальную консультацию в удобное время',
    link: SOCIAL_LINKS.whatsapp,
    linkText: 'Оставить заявку',
    imgPath: banner3,
  },
];
