import { v4 as uuidv4 } from 'uuid';
import unicom24Icon from '../../../assets/images/companies/unicom24.png';
import sovcombankIcon from '../../../assets/images/companies/sovcombank.png';

interface CompaniesWeWorkedDataItem {
  id: string;
  src: string;
  nameCompany: string;
  link?: string;
}

export const companiesWeWorkedData: CompaniesWeWorkedDataItem[] = [
  {
    id: uuidv4(),
    src: unicom24Icon,
    nameCompany: 'Юником24',
    link: '',
  },
  {
    id: uuidv4(),
    src: sovcombankIcon,
    nameCompany: 'Совкомбанк',
    link: '',
  },
];
