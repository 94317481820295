import { Accordion, Container, SectionTitle, Tag } from '@/components';
import { PADDINGS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';
import { faqData, mainData } from './data';

const Faq: FC = () => {
  return (
    <section>
      <StyledContainer>
        <Head>
          <StyledTag text={mainData.tag} />
          <StyledSectionTitle>{mainData.title}</StyledSectionTitle>
        </Head>
        <StyledAccordion data={faqData} />
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-top: ${PADDINGS.paddingBetweenSectionsLarge};
  padding-bottom: 60px;
  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsSmall};
  }
`;

const Head = styled.div`
  margin-bottom: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    margin-bottom: 54px;
  }
`;

const StyledTag = styled(Tag)`
  margin-bottom: 12px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  max-width: 400px;
  text-align: center;
  letter-spacing: 0.01em;
  @media (max-width: 768px) {
    letter-spacing: normal;
    ${TYPOGRAPHY.title4Medium28};
  }
`;

const StyledAccordion = styled(Accordion)`
  max-width: 996px;
  margin: 0 auto;
`;

export default Faq;
