import React, { FC } from 'react';
import { Container, SectionTitle, Tag } from '@/components';
import { COLORS, PADDINGS, SECTION_IDS, TYPOGRAPHY } from '@/constants';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { UiLink } from '@/ui/components';
import lineArrow from '../../../assets/images/svg/line-arrow.svg';
import { mainData, textData } from './data';

const Steps: FC = () => {
  return (
    <Root id={SECTION_IDS.steps}>
      <StyledContainer>
        <Head>
          <StyledTag text={mainData.tag} />
          <StyledSectionTitle>{mainData.title}</StyledSectionTitle>
        </Head>
        <SliderContainer>
          <Swiper
            modules={[Pagination]}
            spaceBetween={24}
            pagination={{ clickable: true }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 2,
              },
              900: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            {textData.map(({ title, description, Icon }, index) => {
              return (
                <SwiperSlide key={index}>
                  <SlideContent>
                    <IconWrapper>{<Icon />}</IconWrapper>
                    <ItemTitle>{title}</ItemTitle>
                    <ItemDescription>{description}</ItemDescription>
                  </SlideContent>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SliderContainer>
        <StyledUiLink link={mainData.link} text={mainData.linkText} />
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  overflow: hidden;
  background-color: ${COLORS.grayLight};
`;

const StyledContainer = styled(Container)`
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    padding-top: ${PADDINGS.paddingBetweenSectionsMedium};
    padding-bottom: ${PADDINGS.paddingBetweenSectionsMedium};
  }
`;

const Head = styled.div`
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTag = styled(Tag)`
  margin-bottom: 12px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  letter-spacing: 0.01em;
  text-align: center;

  br {
    display: none;
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.title3SemiBold32};
    letter-spacing: normal;
    br {
      display: block;
    }
  }
`;

const SliderContainer = styled.div`
  .swiper {
    overflow: initial;
  }
  .swiper-wrapper {
    padding-bottom: 64px;
  }
  .swiper-pagination {
    display: none;
  }
  .swiper-slide {
    display: flex;
    height: auto;
  }
  body & .swiper-pagination-bullet {
    background-color: ${COLORS.primaryLight};
  }
  body & .swiper-pagination-bullet-active {
    background-color: ${COLORS.blue1};
  }
  body & .swiper-horizontal > .swiper-pagination-bullets,
  body & .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 16px;
  }
  @media (max-width: 1200px) {
    .swiper-pagination {
      display: block;
    }
  }
`;

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ::before {
    content: '';
    height: 7px;
    width: 64.18%;
    position: absolute;
    top: 32px;
    left: 72%;
    background: url(${lineArrow}) no-repeat right;
    background-size: cover;
  }
  .swiper-slide:last-child & {
    ::before {
      content: none;
    }
  }
  @media (max-width: 1200px) {
    ::before {
      content: none;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${COLORS.additionalGreen};
  margin-bottom: 24px;

  border-radius: 50%;
`;

const ItemTitle = styled.h3`
  ${TYPOGRAPHY.headline2SemiBold22};
  color: ${COLORS.black};
  text-align: center;
  margin-bottom: 8px;
`;

const ItemDescription = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker};
  text-align: center;
`;

const StyledUiLink = styled(UiLink)`
  padding-top: 12px;
  padding-bottom: 12px;
  align-self: center;
`;

export default Steps;
